import React, { useState, useEffect } from "react";
import axios from "axios";
import DefaultLayout from "../layouts/Default";
import DataTable from "../components/dataTable/DataTable";
import { useParams } from "react-router-dom";
import EntityDetailLayout from "../layouts/EntityDetail";
import { defaultData } from "../types/CategoryItem";
import TextInput from "../components/TextInput";
import AutocompleteField from "../components/AutocompleteField";
import MediaPicker from "../components/MediaPicker";
import {
  defaultTestimonialsItem,
  TestimonialsItem,
} from "../types/TestimonialsItem";
import { getImageForm, handleResponseError } from "../utils/methods";
import { getAuth } from "../utils/Auth";
import { API_URL } from "../utils/constants";
import { OurWorkItem } from "../types/OurWorkItem";
import OptionPicker from "../components/OptionPicker";
import { Status } from "../types/global";

const tableHeader = [
  { name: "ID", attr: "id" },
  { name: "Title", attr: "title" },
  { name: "Sub Title", attr: "sub_title" },
];

const main_title = "Testimonials";
const urls = "/testimonial";

export default function Testimonials() {
  const { entityId } = useParams();
  const [ourWorkList, setOurWorkList] = useState<OurWorkItem[]>([]);
  const [detailData, setDetailData] = useState<TestimonialsItem>(
    defaultTestimonialsItem
  );

  const getFormData = () => {
    return {
      title: detailData.title,
      sub_title: detailData.sub_title,
      company_logo: getImageForm(detailData.company_logo),
      video: getImageForm(detailData.video),
      video_placeholder_image: getImageForm(detailData.video_placeholder_image),
      our_work: detailData.our_work,
      person: detailData.person,
      status: detailData.status,
    };
  };

  const changeData = (attr: string, value: any) => {
    setDetailData((prev) => {
      return { ...prev, [attr]: value };
    });
  };

  function getUsers() {
    axios
      .get(`${API_URL}/our-work?limit=0`, getAuth())
      .then((res) => {
        setOurWorkList(res.data.data);
      })
      .catch((err) => {
        handleResponseError(err.response, "while loading our work records.");
      });
  }

  useEffect(() => {
    getUsers();
  }, []);

  function onChangeAutocompleteField(event: any, newValue: string | null) {
    changeData(
      "our_work",
      ourWorkList.filter((ourWork) => ourWork.slug === newValue)[0]
    );
  }

  const isEditing = () => entityId?.toLowerCase() !== "new";

  if (entityId) {
    return (
      <DefaultLayout>
        <EntityDetailLayout
          id={detailData.id}
          title={main_title}
          apiUrl={urls}
          defaultValue={defaultData}
          setData={setDetailData}
          getFormData={getFormData}
          navigateBack={isEditing() ? false : true}
          showUsedInForEntity="testimonial"
          refreshUsedInOnSave
        >
          <TextInput
            value={detailData.title}
            onChange={changeData}
            id={"title"}
            label={"Title"}
            validators={[{ required: true }, { maxLimit: 255 }]}
          />
          <TextInput
            value={detailData.sub_title}
            onChange={changeData}
            id={"sub_title"}
            label={"Sub Title"}
            validators={[{ required: true }, { maxLimit: 500 }]}
          />
          <TextInput
            value={detailData.person}
            onChange={changeData}
            id={"person"}
            label={"Person"}
            validators={[{ maxLimit: 150 }]}
          />
          <AutocompleteField
            id="our_work"
            label="Our work slug"
            options={ourWorkList.map((ourWork) => ourWork.slug)}
            onChange={onChangeAutocompleteField}
            validators={[{ required: true }]}
            value={detailData.our_work?.slug}
          />
          <OptionPicker
            value={detailData.status}
            id={"status"}
            label={"Status"}
            validators={[{ required: true }]}
            onChange={changeData}
            options={Object.values(Status)}
          />
          <MediaPicker
            value={detailData.company_logo}
            onChange={changeData}
            id={"company_logo"}
            label={"Company Logo"}
          />
          <MediaPicker
            value={detailData.video}
            onChange={changeData}
            id={"video"}
            label={"Video"}
            mediaType={"video"}
          />
          <MediaPicker
            value={detailData.video_placeholder_image}
            onChange={changeData}
            id={"video_placeholder_image"}
            label={"Video Placeholder Image"}
          />
        </EntityDetailLayout>
      </DefaultLayout>
    );
  }

  return (
    <DefaultLayout>
      <DataTable
        title={main_title}
        apiUrl={urls}
        url={"/testimonials"}
        tableHeader={tableHeader}
      />
    </DefaultLayout>
  );
}
