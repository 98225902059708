import React, { useEffect, useState } from "react";
import DefaultLayout from "../../layouts/Default";
import EntityDetailLayout from "../../layouts/EntityDetail";
import { Editor, EditorState } from "react-draft-wysiwyg";
import Draft, { convertToRaw } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { defaultData, DocumentItem } from "../../types/DocumentItem";
import axios from "axios";
import { API_URL } from "../../utils/constants";
import { getAuth } from "../../utils/Auth";
import { handleResponseError } from "../../utils/methods";
import { LanguageItem } from "../../types/LanguageItem";
import OptionPicker from "../../components/OptionPicker";
import { InputLabel, MenuItem, Select } from "@mui/material";
import { useParams } from "react-router-dom";
import { draftToMarkdown } from "markdown-draft-js";

export default function DocumentsDetail() {
  const { entityId } = useParams();
  const [detailData, setDetailData] = useState<DocumentItem>(defaultData);

  const [editorState, setEditorState] = useState<EditorState>();
  const [languages, setLanguages] = useState<LanguageItem[]>();
  const [locale, setLocale] = useState<LanguageItem>();
  const [types, setTypes] = useState<string[]>();
  const isEditing = () => entityId?.toLowerCase() !== "new";

  const fetchLanguages = () => {
    axios
      .get(`${API_URL}/language`, getAuth())
      .then((res) => {
        setLanguages(res.data.data);
        setLocale(res.data.data[0]);
      })
      .catch((err) => {
        handleResponseError(err.response, "while loading languages");
      });
  };

  const fetchTypes = () => {
    axios
      .get(`${API_URL}/documents/types`, getAuth())
      .then((res) => {
        setTypes([...res.data]);
      })
      .catch((err) => {
        handleResponseError(err.response, "while loading types");
      });
  };

  useEffect(() => {
    changeLocaleAndSetInput();
  }, [locale, detailData]);

  const setData = (document: DocumentItem) => {
    setDetailData(document);
  };

  const onEditorStateChange = (editorState: EditorState) => {
    setDetailData((prev) => {
      prev.documentContents
        .filter((document) => document.languageId === locale?.id)
        .map((document) => {
          document.content = draftToMarkdown(
            convertToRaw(editorState.getCurrentContent()),
            {
              preserveNewlines: true,
            }
          );
          return true;
        });
      return prev;
    });
    setEditorState(editorState);
  };

  const getFormData = () => {
    return {
      id: detailData.id,
      type: detailData.type,
      documentContents: detailData.documentContents,
    };
  };

  const changeData = (attr: string, value: any) => {
    setDetailData((prev) => {
      return { ...prev, [attr]: value };
    });
  };

  const onLocaleChange = (event: string) => {
    setLocale((prev) => {
      return languages?.find((language) => language.isoCode === event);
    });
    changeLocaleAndSetInput();
  };

  const changeLocaleAndSetInput = () => {
    if (detailData && locale) {
      const documentContent =
        detailData.documentContents.find(
          (content) => content.languageId === locale?.id
        ) ?? detailData.documentContents[0];
      try {
        if (documentContent) {
          const state = Draft.EditorState.createWithContent(
            Draft.ContentState.createFromText(documentContent.content)
          );
          setEditorState(state);
        } else {
          setEmptyDocumentStates();
          setEditorState(Draft.EditorState.createEmpty());
        }
      } catch (err) {
        setEditorState(Draft.EditorState.createEmpty());
      }
    }
  };

  const setEmptyDocumentStates = () => {
    if (languages && detailData && detailData.documentContents.length === 0) {
      const documentContents = languages.map((language) => {
        return {
          id: -1,
          content: "",
          languageId: language.id,
        };
      });
      setDetailData({ ...detailData, documentContents: documentContents });
    }
  };

  useEffect(() => {
    fetchLanguages();
    if (!isEditing()) {
      fetchTypes();
    }
  }, []);

  return (
    <DefaultLayout>
      <EntityDetailLayout
        id={detailData.id}
        title={isEditing() ? detailData.type : "Document content"}
        apiUrl={"/documents"}
        defaultValue={defaultData}
        setData={setData}
        getFormData={getFormData}
        noDelete={!isEditing()}
      >
        {types && (
          <OptionPicker
            value={detailData.type}
            id={"type"}
            label={"Document Type"}
            validators={[{ required: true }]}
            onChange={changeData}
            options={types}
          />
        )}
        <div
          style={{
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
            gap: 10,
            flexWrap: "wrap",
            margin: "20px",
          }}
        >
          <InputLabel className="rdw-storybook-locale-lbl">
            Select Language
          </InputLabel>
          <Select
            value={locale?.isoCode ?? ""}
            style={{
              height: "30px",
            }}
            onChange={(event) => onLocaleChange(event.target.value)}
          >
            {languages?.map((language) => {
              return (
                <MenuItem value={language.isoCode} key={language.id}>
                  {language.name}
                </MenuItem>
              );
            })}
          </Select>
        </div>
        <Editor
          toolbar={{
            options: [],
          }}
          editorState={editorState}
          wrapperClassName="demo-wrapper"
          editorClassName="demo-editor"
          onEditorStateChange={onEditorStateChange}
        />
      </EntityDetailLayout>
    </DefaultLayout>
  );
}
